
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "edit-company-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  setup(props) {
    // this.$forceUpdate();
    const store = useStore();
    const router = useRouter();
    var formData = ref({});

    const setFormData = async (data) => {
      console.log(data.ids);
      var j = 0;

      try {
        var values = { company_id: data.ids, page: 1, records_per_page: 1 };

        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            formData.value = {
              id: data.company_id,
              name: data.company_name,
              alternativename: data.company_name_alternative,
              nob_select: data.company_nob_id,
              group_type_select: data.company_group_type_id,
              industry_type_select: data.company_industry_type_id,
              business_type_select: data.company_business_type_id,
              incorporationateDate: data.company_doi,
              pan: data.company_pan,
              cinn:
                data.company_business_type_id != 6
                  ? data.company_cin_llpin
                  : "",
              llpinn:
                data.company_business_type_id == 6
                  ? data.company_cin_llpin
                  : "",
              registrationNo: data.company_registration_number,
              roc_select: data.company_roc_id,
              website: data.company_website,
              active: data.active ? "Yes" : "No",
              verified: data.company_verified_yes_no ? "Yes" : "No",
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await setFormData(props.data);
    });

    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const editCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    getAddData();

    const rules = ref({
      name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      nob_select: [
        {
          required: true,
          message: "Nature of Business is required",
          trigger: "change",
        },
      ],
      group_type_select: [
        {
          required: true,
          message: "Group Type is required",
          trigger: "change",
        },
      ],
      industry_type_select: [
        {
          required: true,
          message: "Industry Type is required",
          trigger: "change",
        },
      ],
      business_type_select: [
        {
          required: true,
          message: "Business Type is required",
          trigger: "change",
        },
      ],
      incorporationateDate: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      pan: [
        {
          required: true,
          message: "PAN is required",
          trigger: "change",
          min: 10,
          max: 10,
        },
      ],
      cinn: [
        {
          required: true,
          message: "CIN / LLPIN is required",
          trigger: "change",
          min: 21,
          max: 21,
        },
      ],
      llpinn: [
        {
          required: true,
          message: "CIN / LLPIN is required",
          trigger: "change",
          min: 8,
          max: 8,
        },
      ],
      registrationNo: [
        {
          required: true,
          message: "Registration No is required",
          trigger: "change",
          min: 6,
          max: 6,
        },
      ],
      roc_select: [
        {
          required: true,
          message: "ROC is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var dtt = "";
      var cinLlpin = "";
      var user = JSON.parse(JwtService.getToken());

      try {
        if (data.incorporationateDate.includes("-")) {
          dtt = data.incorporationateDate;
        }
      } catch {
        console.log("aa");
      }

      if (dtt) {
        console.log("aa");
      } else {
        var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
        var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
        var yy = data.incorporationateDate.getFullYear();
        dtt = yy + "-" + mo + "-" + dt;
      }

      cinLlpin = data.llpinn == "" ? data.cinn : data.llpinn;
      const db_data = {
        company_id: data.id,
        company_name: data.name,
        company_name_alternative: data.alternativename,
        company_nob: data.nob_select,
        company_group_type: data.group_type_select,
        company_industry_type: data.industry_type_select,
        company_business_type: data.business_type_select,
        company_roc: data.roc_select === "" ? 0 : data.roc_select,
        company_doi: dtt,
        company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
        company_registration_number:
          data.registrationNo === "" ? 0 : data.registrationNo,
        company_pan: data.pan,
        company_website: data.website,
        company_verified_yes_no: data.verified == "Yes" ? true : false,
        company_trade_type_id: 0,
        active: data.active == "Yes" ? true : false,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_EDIT_COMPANY, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Company has been successfully edited.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editCompanyModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      editCompanyModalRef,
    };
  },
});
