
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import PaymentMethods from "@/components/customers/cards/overview/PaymentMethods.vue";
import CreditBalance from "@/components/customers/cards/overview/CreditBalance.vue";
import Invoices from "@/components/customers/cards/overview/Invoices.vue";

import BranchesListing from "@/views/apps/branches/BranchesListing.vue";
import KmpsCompListing from "@/views/apps/kmps/KmpsCompListing.vue";

import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";
import axios from "axios";
import EditCompanyModal from "@/components/modals/forms/EditCompanyModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

// console.log(this.$route.query.test)

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "companys-details",
  components: {
    // PaymentRecords,
    // PaymentMethods,
    // CreditBalance,
    // Invoices,
    EditCompanyModal,
    // Earnings,
    // Statement,
    Dropdown3,
    NewCardModal,
    BranchesListing,
    KmpsCompListing,
  },
  methods: {},
  setup(props) {
    var ids = ref(props.id);
    const store = useStore();
    const loadingData = ref<boolean>(true);

    interface ViewCompanies {
      company_id;
      company_cin_llpin;
      company_roc;
      company_industry;
      company_website;
      company_name;
      company_name_alternative;
      company_pan;
      company_group;
      company_doi;
      company_registration_number;
      company_business_type;
      company_nob;
      company_avatar;
      company_verified: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
    }

    var resultsView = ref<ViewCompanies>();

    resultsView.value = {
      company_id: "",
      company_cin_llpin: "",
      company_roc: "",
      company_industry: "",
      company_website: "",
      company_name: "",
      company_name_alternative: "",
      company_pan: "",
      company_group: "",
      company_doi: "",
      company_registration_number: "",
      company_business_type: "",
      company_nob: "",
      company_avatar: "",
      company_verified: {
        label: "",
        color: "",
      },
      active: {
        label: "",
        color: "",
      },
    };

    const getUsers = async () => {
      try {
        var values = { company_id: props.id, page: 1, records_per_page: 10 };

        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            var j = 0;

            if (data.company_verified_yes_no) {
              status_label = "Verified";
              status_color = "success";
            } else {
              status_label = "Unverified";
              status_color = "danger";
            }

            if (data.active) {
              active_label = "Yes";
              active_color = "success";
            } else {
              active_label = "No";
              active_color = "danger";
            }

            resultsView.value = {
              company_id: data.company_id,
              company_cin_llpin: data.company_cin_llpin,
              company_roc: data.company_roc_name,
              company_industry: data.company_industry_type_name,
              company_website: data.company_website,
              company_name: data.company_name,
              company_name_alternative: data.company_name_alternative,
              company_pan: data.company_pan,
              company_group: data.company_group_type_name,
              company_doi: data.company_doi,
              company_registration_number: data.company_registration_number,
              company_business_type: data.company_business_type_name,
              company_nob: data.company_nob_name,
              company_avatar:
                "https://cdn.elogicals.com/customers/" +
                data.company_id +
                "/logo/" +
                data.company_id +
                ".png",
              company_verified: {
                label: status_label,
                color: status_color,
              },
              active: {
                label: active_label,
                color: active_color,
              },
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await getUsers();
      setCurrentPageBreadcrumbs("Company Details", ["Company"]);
    });

    const uploadImage = async (e: Event) => {
      // console.log(e.target?.files[0]);
      // var filsse = document?.querySelector('input[type=file]');
      //  console.log((<HTMLInputElement>document.getElementById('comp_logo')))
      // const file = document.querySelector('input[type=file]')?.files[0];
      // console.log(file);
      // const image_value = (<HTMLInputElement>e.target).value;
      // const image_name = image_value.replace(/^.*[\\\/]/, "");
      // const image_format = /[.]/.exec(image_name)
      //   ? /[^.]+$/.exec(image_name)
      //   : undefined;
      // let image_file_encoded = "";
      // const final_encoded_name =  ids + "." + image_format;
      // console.log(final_encoded_name);
      // // let file = filsse?.files[0];
      // let reader = new FileReader();
      // reader.readAsDataURL(file);
      // console.log(reader);
      // reader.onloadend = () => {
      //   image_file_encoded = reader.result?.replace(
      //     /^data:image\/[a-z]+;base64,/,
      //     ""
      //   );
      //   this.update_image(final_encoded_name, image_file_encoded);
      //   this.setState({
      //     image_file_encoded: image_file_encoded,
      //     final_encoded_name: final_encoded_name,
      //     image_name: image_name,
      //   });
      // }
    };

    //     const update_image = async () => {

    //       const data = {
    //         path: "company_image",
    //         fileName: ""
    // ,        file64: ""
    //       };

    //       try {
    //         await axios.post(
    //           process.env.VUE_APP_CUSTOME_APP_API_URL+"company/list",
    //           data,
    //           {
    //             headers: {
    //               "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
    //             },
    //           }
    //         ).then(({ data }) => {

    //         })
    //         .catch(({ response }) => {

    //           console.log(response);

    //         });

    //         } catch (e) {
    //           console.log(e);
    //         }
    //     }

    const removeImage = () => {
      // profileDetails.value.avatar = "/media/avatars/blank.png";
    };

    return {
      resultsView,
      removeImage,
      uploadImage,
      ids,
    };
  },
});
