
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  setup(props) {
    const store = useStore();
    const nob_data = business_natures;
    const group_type_data = group_types;
    const industry_types_data = industry_types;
    const business_types_data = business_types;
    const address_title_data = ref([]);

    const roc_list_data = roc_list;
    const formRef = ref<null | HTMLFormElement>(null);
    const addBranchModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();

    const formData = ref({
      name: "",
      business_landline: "",
      business_emails: "",
      business_mobile: "",
      address_line_1: "",
      address_line_2: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      gstin: "",
      state_tin: "",
      gst_type: "",
      gst_return_frequency: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",
        },
      ],
      business_emails: [
        {
          required: true,
          message: "Email Name is required",
          trigger: "change",
        },
      ],
      business_mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
          min: 10,
          max: 10,
        },
      ],
      address_line_1: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      pincode: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "change",
          min: 6,
          max: 6,
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      country: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
      gstin: [
        {
          required: true,
          message: "GSTIN is required",
          trigger: "change",
          min: 15,
          max: 15,
        },
      ],
      gst_type: [
        {
          required: true,
          message: "Gst Type is required",
          trigger: "change",
        },
      ],
      state_tin: [
        {
          required: true,
          message: "State Tin is required",
          trigger: "change",
        },
      ],
      gst_return_frequency: [
        {
          required: true,
          message: "GST Return frequency is required",
          trigger: "change",
        },
      ],
    });

    getAddressTitleData();

    const getPincodeData = async () => {
      loadingPin.value = true;

      const db_data = { search_term: formData.value.pincode };

      await store
        .dispatch(Actions.CUST_GET_PINCODE, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value.city = data["city_name"];
            formData.value.state = data["state_name"];
            formData.value.country = "India";
            formData.value.state_tin = data["tin"];
            formData.value.city_id = data["city_id"];
            formData.value.state_id = data["state_id"];
            formData.value.pincode_id = data["pincode_id"];
            formData.value.country_id = "1";

            loadingPin.value = false;
          } else {
            formData.value.city = "";
            formData.value.state = "";
            formData.value.country = "";
            formData.value.state_tin = "";
            formData.value.city_id = "";
            formData.value.state_id = "";
            formData.value.pincode_id = "";
            formData.value.country_id = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    async function setBranchData(data) {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        company_branch_id: 0,
        branch_title_type_id: 0,
        branch_name: data.name,
        company_id: props.id,
        business_email_json: data.business_emails,
        business_mobile_json: data.business_mobile,
        business_landline_json: data.business_landline,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        pincode_id: data.pincode_id,
        city_id: data.city_id,
        state_id: data.state_id,
        country_id: data.country_id,
        gstin: data.gstin,
        state_tin: data.state_tin,
        gst_type: data.gst_type,
        gst_return_frequency: data.gst_return_frequency,
        verified_yes_no: false,
        active: true,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_BRANCH, db_data)
        .then(({ data }) => {
          if (data.company_branch_id) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Branch has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addBranchModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBranchData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getAddressTitleData() {
      await store
        .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
        .then(({ data }) => {
          address_title_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      address_title_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      getPincodeData,
      getAddressTitleData,
      formRef,
      loading,
      loadingPin,
      addBranchModalRef,
    };
  },
});
