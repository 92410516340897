

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddBranchModal from "@/components/modals/forms/AddBranchModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({

  name: "customers-listing",
  props: {    
    id: { type: Object }
    
  },
  components: {
    ExportCustomerModal,
    AddBranchModal,     
  },
  
  setup(props) {

    var ids = ref(props['id']);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);    
    const loadingData = ref<boolean>(true);

    interface IBranches {
      company_id,
      company_branch_id,
      gstin,
      city_name,
      state_name,
      branch_name,
      status: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {
      paginationData.value['activePage']  = 1;      
      getBranchList(ids)
    }

    const changePageChange = (page) => {
      paginationData.value['activePage']  = page;
      getBranchList(ids)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getBranchList = async (data) => {

      loadingData.value = true;
      
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      
      try {
        
        var values = { "company_id"  : com_id, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage']) }
        
        await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {

          tableData.value = ([]);

          //pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }

          var resultsM = ref<Array<IBranches>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""

          for (let j = 0; j < data.result_list.length; j++) {          

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({

              company_id : data.result_list[j]['company_id'],
              company_branch_id : data.result_list[j]['company_branch_id'],
              gstin : data.result_list[j]['gstin'],
              city_name : data.result_list[j]['city_name'],
              state_name : data.result_list[j]['state_name'],
              branch_name : data.result_list[j]['branch_name'],
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              }
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        })
        .catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

        });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getBranchList(props.id)
        // setCurrentPageBreadcrumbs("Branches",[]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);              
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].company_branch_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getBranchList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      ids,
    };  

  }  

});




