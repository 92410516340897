

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddkmpsModal from "@/components/modals/forms/AddkmpsModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({

  name: "customers-listing",
  props: {    
    id: { required: true }
  },
  components: {
    ExportCustomerModal,
    AddkmpsModal,     
  },
  
  setup(props) {
    
    var ids = ref(props.id);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);  
    const loadingData = ref<boolean>(true);  

    interface IBranches {
      kmp_id,
      kmp_din,
      kmp_dob,
      kmp_gender,
      kmp_email_id,
      kmp_name,
      kmp_mobile_no,
      kmp_designation,      
      active: {
        label: string;
        color: string;
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,
        mmaxPageg : 6,
    }

    const refreshData =() => {      
      getKmpCompList(props.id)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;
      getKmpCompList(props.id)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getKmpCompList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = { "company_id"  :  data}
        await store.dispatch(Actions.CUST_GET_KMP_COMP_LIST, values).then(({ data }) => {

        tableData.value = ([]);
        
        var resultsM = ref<Array<IBranches>>([])
        var active_label = ""
        var active_color = ""

        for (let j = 0; j < data.result_list.length; j++) {

          if (data.result_list[j]['active']){
              active_label = "Yes";
              active_color = "success";                  
          }else{
              active_label = "No";
              active_color = "danger";
          }

          resultsM.value = Array({

            kmp_id : data.result_list[j]['kmp_id'],
            kmp_din : data.result_list[j]['kmp_din'],
            kmp_dob : data.result_list[j]['kmp_dob'],
            kmp_gender : data.result_list[j]['kmp_gender_name'] == 'NA'  ? "-" : data.result_list[j]['kmp_gender_name'],
            kmp_email_id : data.result_list[j]['kmp_email_id'],
            kmp_name : data.result_list[j]['kmp_name'],
            kmp_mobile_no : data.result_list[j]['kmp_mobile_no'],
            kmp_designation : data.result_list[j]['kmp_designation_name'],
            active: {
              label: active_label,
              color: active_color
            }

          })

          tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
        }

        loadingData.value = false;

      })
      .catch(({ response }) => {
        
        tableData.value = [];
        loadingData.value = false; 
      });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getKmpCompList(props.id)
        // setCurrentPageBreadcrumbs("KMP's",["company"]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);                
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].kmp_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          

    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getKmpCompList,       
      paginationData,
      changePageChange,
      refreshData,      
      loadingData,
      ids
    };  

  }  

});


