
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { designation_list } from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  setup(props) {
    const store = useStore();
    const designation_item = designation_list;
    const formRef = ref<null | HTMLFormElement>(null);
    const addkmpModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingDin = ref<boolean>(false);
    const router = useRouter();

    const formData = ref({
      name: "",
      din: "",
      dob: "",
      gender: "",
      id: "",
      mobile: "",
      email: "",
      designation_select: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
        },
      ],
      din: [
        {
          required: true,
          message: "DIN is required",
          trigger: "change",
          min: 8,
          max: 8,
        },
      ],
      dob: [
        {
          required: true,
          message: "DOB is required",
          trigger: "change",
        },
      ],
      designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ],
      gender: [
        {
          required: true,
          message: "Gender is required",
          trigger: "change",
        },
      ],
    });

    const getDinData = async () => {
      loadingDin.value = true;

      const db_data = {
        kmp_id: 0,
        kmp_din: formData.value.din,
        page: 1,
        records_per_page: 10,
      };

      await store
        .dispatch(Actions.CUST_GET_DIN, db_data)
        .then(({ data }) => {
          if (data) {
            formData.value.dob = data["kmp_dob"];
            formData.value.gender = data["kmp_gender_name"];
            formData.value.email = data["kmp_email_id"];
            formData.value.mobile = data["kmp_mobile_no"];
            formData.value.designation_select = data["kmp_designation_name"];
            formData.value.id = data["kmp_id"];
            formData.value.name = data["kmp_name"];

            loadingDin.value = false;
          } else {
            formData.value.dob = "";
            formData.value.gender = "";
            formData.value.email = "";
            formData.value.mobile = "";
            formData.value.designation_select = "";
            formData.value.id = "";
            formData.value.name = "";

            Swal.fire({
              text: "Sorry, It's Invalid DIN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingDin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid DIN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingDin.value = false;
        });
    };

    const setKmpCompData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        company_id: props.id,
        kmp_id: data.id,
        active: 1,
        created_user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_LINK_KMP, db_data)
        .then(({ data }) => {
          if (data.company_kmp_id) {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "KMP has been successfully linked.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addkmpModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setKmpCompData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      designation_item,
      rules,
      submit,
      getDinData,
      formRef,
      loading,
      loadingDin,
      addkmpModalRef,
    };
  },
});
